import React from "react";
import CurrencyFormat from "react-currency-format";

export default function CustomListTwo({
  result,
  inputValueMoblie,
  setInputValueMoblie,
  handleSubmitCefProjectMobile,
  isolationData,
  pacChauffePoele,
  scenario,
}) {
  console.log(isolationData);
  const handleChange = (e) => {
    setInputValueMoblie(e.target.value);
  };
  return (
    <ul className="bg-gray-50">
      <div
        style={{
          border: "1px solid #1b5fa8",
        }}
      >
        <li className="flex justify-center px-6 py-2 text-xs text-gray-500 border-b">
          {/* <span>
            <strong>SC1: &nbsp;</strong>
          </span> */}
          <strong>ISOLATION</strong>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span
            style={{
              width: "66px",
            }}
          >
            {" "}
            <strong> ITE</strong>
          </span>
          <span>{isolationData?.ITE + " m²"}</span>
          {/* <CurrencyFormat */}
          {/* thousandSeparator={true} */}
          {/* displayType={"text"} */}
          {/* /> */}

          {/* <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.ITE}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span> */}
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong>RAMPANTS</strong>
          </span>
          <span>
            {/* <CurrencyFormat */}
            {isolationData?.RAMPANTS + " m²"}
            {/* thousandSeparator={true} */}
            {/* displayType={"text"} */}
            {/* /> */}
          </span>

          {/* <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.RAMPANTS}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span> */}
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
              width: "66px",
            }}
          >
            <strong> COMBLES</strong>
          </span>

          <span>
            {/* <CurrencyFormat */}
            {isolationData?.COMBLES + " m²"}

            {/* thousandSeparator={true} */}
            {/* displayType={"text"} */}
            {/* /> */}
          </span>

          {/* <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.COMBLES}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span> */}
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
              width: "66px",
            }}
          >
            <strong>PLANCHER BAS</strong>
          </span>

          <span>
            {/* <CurrencyFormat */}
            {isolationData?.PLANCHERBAS + " m²"}

            {/* thousandSeparator={true} */}
            {/* displayType={"text"} */}
            {/* /> */}
          </span>

          {/* <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.COMBLES}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span> */}
        </li>
      </div>
      {/* <div
        style={{
          border: "1px solid #1b5fa8",
          marginTop: "1rem",
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
        }}
      >
        <li className="flex justify-center px-6 py-2 text-xs text-gray-500 border-b">
          {/* <span>
            <strong>SC1: &nbsp;</strong>
          </span> */}
      {/* <strong>COUT TRAVAUX CHAUFFAGE</strong>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span
            style={{
              maxWidth: "120px",
            }}
          >
            {" "}
            <strong> PAC AIR/EAU OU AIR/AIR</strong>
          </span>
          <span>{pacChauffePoele?.pac}</span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.pacCout}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.pacEauAir}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b ">
          <span
            style={{
              maxWidth: "120px",
            }}
          >
            <strong>CHAUFFE-EAU THERMODYNAMIQUE</strong>
          </span>
          <span>{pacChauffePoele?.chauffe}</span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.chauffeCout}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.chaufEauTHermodynamique}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> POELE A GRANNULÉ</strong>
          </span>

          <span>{pacChauffePoele?.poeleGrannule}</span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.poeleGrannuleCout}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.PoeleAGrannule}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li> */}
      {/* </div>  */}
      <div
        style={{
          border: "1px solid #1b5fa8",
          marginTop: "1rem",
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
        }}
      >
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          {/* <span>
            <strong>SC1: &nbsp;</strong>
          </span> */}
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> SCENARIOS</strong>
          </span>
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> COUT TRAVAUX</strong>
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "200px" }}>
            {" "}
            <strong> ITE + PAC + BALLON </strong>
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.itePacBaloon?.cout}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "120px" }}>
            <strong> RAMPANTS + PAC + BALLON</strong>
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.ramponPacBaloonPoele.cout}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "200px" }}>
            <strong> COMBLES + PAC + BALLON</strong>
          </span>

          <span>
            {/* <CurrencyFormat */}

            <CurrencyFormat
              prefix={"€"}
              value={result?.comblesPacBaloonPoele.cout}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        {/* <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "200px" }}>
            <strong>PLANCHER BAS + PAC + BALLON</strong>
          </span>

          <span>


            <CurrencyFormat
              prefix={"€"}
              value={result?.comblesPacBaloonPoele.cout}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li> */}
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "200px" }}>
            <strong>
              {isolationData?.ITE !== 0 && "ITE "}
              {isolationData?.ITE !== 0 && isolationData?.COMBLES !== 0 && "+"}
              {isolationData?.COMBLES !== 0 && " COMBLES "}
              {((isolationData?.RAMPANTS !== 0 &&
                isolationData?.COMBLES !== 0) ||
                (isolationData?.ITE !== 0 && isolationData?.RAMPANTS !== 0)) &&
                "+"}
              {isolationData?.RAMPANTS !== 0 && " RAMPANTS "}
              {isolationData?.PLANCHERBAS !== 0 && "+ PLANCHER BAS "}
              {result?.pacEauAir !== 0 && "+ PAC "}
              {result?.chaufEauTHermodynamique !== 0 && "+ BALLON "}
              {result?.PoeleAGrannule !== 0 && "+ POELE "}
            </strong>
          </span>

          <span>
            {/* <CurrencyFormat */}

            <CurrencyFormat
              prefix={"€"}
              value={result?.TOTAL?.cout}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
      </div>
      <div
        style={{
          border: "1px solid #1b5fa8",
          marginTop: "1rem",
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
        }}
      >
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          {/* <span>
            <strong>SC1: &nbsp;</strong>
          </span> */}
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> SCENARIOS</strong>
          </span>
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> DIFFERENCE</strong>
          </span>
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> RAC</strong>
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "120px" }}>
            <strong> ITE + PAC + BALLON </strong>
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.itePacBaloon?.diff}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.itePacBaloon?.rac}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>

        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "120px" }}>
            <strong> RAMPANTS + PAC + BALLON</strong>
          </span>

          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.ramponPacBaloonPoele.diff}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.ramponPacBaloonPoele?.rac}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "120px" }}>
            <strong> COMBLES + PAC + BALLON</strong>
          </span>

          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.comblesPacBaloonPoele?.diff}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.comblesPacBaloonPoele?.rac}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        {/* <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "120px" }}>
            <strong>PLANCHER BAS + PAC + BALLON</strong>
          </span>

          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.PLANCHERBASPacBaloonPoele?.diff}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.PLANCHERBASPacBaloonPoele?.rac}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li> */}
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "120px" }}>
            <strong>
              {isolationData?.ITE !== 0 && "ITE "}
              {isolationData?.ITE !== 0 && isolationData?.COMBLES !== 0 && "+"}
              {isolationData?.COMBLES !== 0 && " COMBLES "}
              {isolationData?.RAMPANTS !== 0 &&
                isolationData?.COMBLES !== 0 &&
                "+"}
              {isolationData?.RAMPANTS !== 0 && " RAMPANTS "}
              {isolationData?.PLANCHERBAS !== 0 && "+ PLANCHER BAS "}
              {result?.pacEauAir !== 0 && "+ PAC "}
              {result?.chaufEauTHermodynamique !== 0 && "+ BALLON "}
              {result?.PoeleAGrannule !== 0 && "+ POELE "}
            </strong>
          </span>

          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.TOTAL?.diff}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.TOTAL?.rac}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
      </div>
    </ul>
  );
}
