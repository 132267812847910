import React from "react";
import CurrencyFormat from "react-currency-format";

export default function CustomListTwo({
  result,
  inputValueMoblie,
  setInputValueMoblie,
  handleSubmitCefProjectMobile,
  isolationData,
  scenario,
}) {
  console.log(isolationData);
  const handleChange = (e) => {
    setInputValueMoblie(e.target.value);
  };
  return (
    <ul className="bg-gray-50">
      <div
        style={{
          border: "1px solid #1b5fa8",
        }}
      >
        <li className="flex justify-center px-6 py-2 text-xs text-gray-500 border-b">
          {/* <span>
            <strong>SC1: &nbsp;</strong>
          </span> */}
          <strong>COUT TRAVAUX ISOLATION</strong>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span
            style={{
              width: "66px",
            }}
          >
            {" "}
            <strong> ITE</strong>
          </span>
          <span>
            {/* <CurrencyFormat */}
            {isolationData?.ITE + " m²"}
            {/* thousandSeparator={true} */}
            {/* displayType={"text"} */}
            {/* /> */}
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ width: "66px" }}>
            <strong> ITI</strong>
          </span>
          <span>{isolationData?.ITI + " m²"}</span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong>RAMPANTS</strong>
          </span>
          <span>
            {/* <CurrencyFormat */}
            {isolationData?.RAMPANTS + " m²"}
            {/* thousandSeparator={true} */}
            {/* displayType={"text"} */}
            {/* /> */}
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
              width: "66px",
            }}
          >
            <strong> COMBLES</strong>
          </span>

          <span>
            {/* <CurrencyFormat */}
            {isolationData?.COMBLES + " m²"}

            {/* thousandSeparator={true} */}
            {/* displayType={"text"} */}
            {/* /> */}
          </span>
        </li>
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
              width: "66px",
            }}
          >
            <strong>PLANCHER BAS</strong>
          </span>

          <span>
            {/* <CurrencyFormat */}
            {isolationData?.PLANCHERBAS + " m²"}

            {/* thousandSeparator={true} */}
            {/* displayType={"text"} */}
            {/* /> */}
          </span>
        </li>
      </div>

      <div
        style={{
          border: "1px solid #1b5fa8",
          marginTop: "1rem",
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
        }}
      >
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          {/* <span>
            <strong>SC1: &nbsp;</strong>
          </span> */}
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> SCENARIOS</strong>
          </span>
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> COUT TRAVAUX</strong>
          </span>
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> DIFFERENCE</strong>
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "65px" }}>
            {" "}
            <strong> ITE + PAC + BALLON </strong>
          </span>
          <span>
            {/* <CurrencyFormat */}

            <CurrencyFormat
              prefix={"€"}
              value={result?.itePacBaloon.cout}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.itePacBaloon.diff}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "65px" }}>
            {" "}
            <strong> ITI + PAC + BALLON </strong>
          </span>
          <span>
            {/* <CurrencyFormat */}

            <CurrencyFormat
              prefix={"€"}
              value={result?.itiPacBaloon.cout}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.itiPacBaloon.diff}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>

        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "65px" }}>
            <strong> RAMPANTS + PAC + BALLON</strong>
          </span>

          <span>
            {/* <CurrencyFormat */}

            <CurrencyFormat
              prefix={"€"}
              value={result?.ramponPacBaloonPoele.cout}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.ramponPacBaloonPoele.diff}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "65px" }}>
            <strong> COMBLES + PAC + BALLON</strong>
          </span>

          <span>
            {/* <CurrencyFormat */}

            <CurrencyFormat
              prefix={"€"}
              value={result?.comblesPacBaloonPoele.cout}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.comblesPacBaloonPoele.diff}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "65px" }}>
            <strong> PLANCHER BAS + PAC + BALLON</strong>
          </span>

          <span>
            {/* <CurrencyFormat */}

            <CurrencyFormat
              prefix={"€"}
              value={result?.PLANCHERBASPacBaloonPoele.cout}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.PLANCHERBASPacBaloonPoele.diff}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong>
              {isolationData?.COMBLES !== 0 && "COMBLE + "}
              PLANCHER BAS + PAC + BALLON
            </strong>
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.CombleRampantPLANCHERBASPacBaloonPoele.cout}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.CombleRampantPLANCHERBASPacBaloonPoele.diff}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span style={{ maxWidth: "65px" }}>
            <strong>
              {isolationData?.ITE !== 0 && "ITE "}
              {isolationData?.ITE !== 0 && isolationData?.ITI !== 0 && "+"}
              {isolationData?.ITI !== 0 && " ITI "}
              {isolationData?.ITI !== 0 && isolationData?.COMBLES !== 0 && "+"}
              {isolationData?.COMBLES !== 0 && " COMBLES "}
              {((isolationData?.RAMPANTS !== 0 &&
                isolationData?.COMBLES !== 0) ||
                (isolationData?.ITE !== 0 && isolationData?.RAMPANTS !== 0)) &&
                "+"}
              {isolationData?.RAMPANTS !== 0 && " RAMPANTS "}
              {isolationData?.PLANCHERBAS !== 0 && "+ PLANCHER BAS "}
              {result?.pacEauAir !== 0 && "+ PAC "}
              {result?.chaufEauTHermodynamique !== 0 && "+ BALLON "}
              {result?.PoeleAGrannule !== 0 && "+ POELE "}
            </strong>
          </span>

          <span>
            {/* <CurrencyFormat */}

            <CurrencyFormat
              prefix={"€"}
              value={result?.TOTAL?.cout}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
          <span>
            <CurrencyFormat
              prefix={"€"}
              value={result?.TOTAL?.diff}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
      </div>
      <div
        style={{
          border: "1px solid #1b5fa8",
          marginTop: "1rem",
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
        }}
      >
        <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
          {/* <span>
            <strong>SC1: &nbsp;</strong>
          </span> */}
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> SCENARIOS</strong>
          </span>
          <span
            style={{
              display: "grid",
              alignItems: "center !important",
            }}
          >
            <strong> RAC</strong>
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            {" "}
            <strong> ITE + PAC + BALLON </strong>
          </span>
          <span>
            {/* <CurrencyFormat */}
            <CurrencyFormat
              prefix={"€"}
              value={result?.itePacBaloon.rac}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong> ITI + PAC + BALLON </strong>
          </span>
          <span>
            {/* <CurrencyFormat */}
            <CurrencyFormat
              prefix={"€"}
              value={result?.itiPacBaloon.rac}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>

        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong> RAMPANTS + PAC + BALLON</strong>
          </span>

          <span>
            {/* <CurrencyFormat */}

            <CurrencyFormat
              prefix={"€"}
              value={result?.ramponPacBaloonPoele.rac}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong> COMBLES + PAC + BALLON</strong>
          </span>

          <span>
            {/* <CurrencyFormat */}

            <CurrencyFormat
              prefix={"€"}
              value={result?.comblesPacBaloonPoele.rac}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong> PLANCHER BAS + PAC + BALLON</strong>
          </span>

          <span>
            {/* <CurrencyFormat */}

            <CurrencyFormat
              prefix={"€"}
              value={result?.PLANCHERBASPacBaloonPoele.rac}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong>
              {isolationData?.COMBLES !== 0 && "COMBLE + "}
              PLANCHER BAS + PAC + BALLON
            </strong>
          </span>

          <span>
            {/* <CurrencyFormat */}

            <CurrencyFormat
              prefix={"€"}
              value={result?.CombleRampantPLANCHERBASPacBaloonPoele.rac}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
        <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
          <span>
            <strong>
              {isolationData?.ITE !== 0 && "ITE "}
              {isolationData?.ITE !== 0 && isolationData?.ITI !== 0 && "+"}
              {isolationData?.ITI !== 0 && " ITI "}
              {isolationData?.ITI !== 0 && isolationData?.COMBLES !== 0 && "+"}
              {isolationData?.COMBLES !== 0 && " COMBLES "}
              {((isolationData?.RAMPANTS !== 0 &&
                isolationData?.COMBLES !== 0) ||
                (isolationData?.ITE !== 0 && isolationData?.RAMPANTS !== 0)) &&
                "+"}
              {isolationData?.RAMPANTS !== 0 && " RAMPANTS "}
              {isolationData?.PLANCHERBAS !== 0 && "+ PLANCHER BAS "}
              {result?.pacEauAir !== 0 && "+ PAC "}
              {result?.chaufEauTHermodynamique !== 0 && "+ BALLON "}
              {result?.PoeleAGrannule !== 0 && "+ POELE "}
            </strong>
          </span>

          <span>
            {/* <CurrencyFormat */}

            <CurrencyFormat
              prefix={"€"}
              value={result?.TOTAL?.rac}
              thousandSeparator={true}
              displayType={"text"}
            />
          </span>
        </li>
      </div>
    </ul>
  );
}
