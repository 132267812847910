import React from "react";
import CurrencyFormat from "react-currency-format";

export default function CustomList({
  result,
  inputValueMoblie,
  setInputValueMoblie,
  handleSubmitCefProjectMobile,
}) {
  return (
    <>
      <ul className="bg-gray-50">
        <div
          style={{
            border: "1px solid #1b5fa8",
          }}
        >
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              {" "}
              <strong> Prime</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.amount1}
                thousandSeparator={true}
                prefix={"€"}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>Cef Initiale</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.cef_initial}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span
              style={{
                display: "grid",
                alignItems: "center !important",
              }}
            >
              <strong> Cef Projet</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.cef_projet1}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>

          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong> Shab</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.shab}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong> B</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.b1}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>CUMAC</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.cumac1}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>SC 1: &nbsp;</strong>
            </span>
            <span>101+103+ PAC +BT / 101+PAC +BT : Inférieur à 110</span>
            {/* <span>
            <strong>SC1: &nbsp;</strong>
          </span>
          <span>Scénario preferentiel : PAC / Ballon / 101 ET/OU 103</span> */}
          </li>
        </div>
        <div
          style={{
            border: "1px solid #1b5fa8",
            marginTop: "1rem",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          }}
        >
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong> Prime</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.amount2}
                thousandSeparator={true}
                prefix={"€"}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>Cef Initiale</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.cef_initial2}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>Cef Projet</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.cef_projet2}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>

          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>Shab</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.shab}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>B</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.b2}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>CUMAC</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.cumac2}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>

          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>SC 2: &nbsp;</strong>
            </span>
            <span>102+ PAC +BT</span>
            {/* <span>
            <strong>SC1: &nbsp;</strong>
          </span>
          <span>Scénario preferentiel : PAC / Ballon / 101 ET/OU 103</span> */}
          </li>
        </div>
        <div
          style={{
            border: "1px solid #1b5fa8",
            marginTop: "1rem",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          }}
        >
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong> Prime</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.amount3}
                thousandSeparator={true}
                prefix={"€"}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>Cef Initiale</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.cef_initial3}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>Cef Projet</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.cef_projet3}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>

          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>Shab</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.shab}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>B</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.b2}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>CUMAC</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.cumac3}
                thousandSeparator={true}
                displayType={"text"}
              />
            </span>
          </li>

          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>SC 3: &nbsp;</strong>
            </span>
            <span>101+103+ITI+ PAC +BT</span>
            {/* <span>
            <strong>SC1: &nbsp;</strong>
          </span>
          <span>Scénario preferentiel : PAC / Ballon / 101 ET/OU 103</span> */}
          </li>
        </div>
      </ul>
      <br />
      <ul className="bg-gray-50">
        <div
          style={{
            border: "1px solid #1b5fa8",
          }}
        >
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              {" "}
              <strong>CUMAC</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.aout?.cumac}
                thousandSeparator={true}
                // prefix={"€"}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong> Prime</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.aout?.prime}
                thousandSeparator={true}
                prefix={"€"}
                displayType={"text"}
              />
            </span>
          </li>

          <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
            <span>
              <strong>RAC</strong>
            </span>
            <span>
              <CurrencyFormat
                value={result?.aout?.rac}
                thousandSeparator={true}
                // prefix={"€"}
                displayType={"text"}
              />
            </span>
          </li>
          <li className="flex justify-between px-6 py-2 text-xs text-center text-red-600 border-b">
            <span>
              Selon l'arrêté du 27 juin 2023 modifiant l'arrêté du 22 décembre
              2014 définissant les opérations standardisées d'économies
              d'énergie et l'arrêté du 29 décembre 2014 relatif aux modalités
              d'application du dispositif des certificats d'économies d'énergie,
              les primes CEE sont écrêté a 3.85 GWh.cumac par logement et
              0.0231 GWh.cumac/m² .
            </span>
            {/* <span>
            <strong>SC1: &nbsp;</strong>
          </span>
          <span>Scénario preferentiel : PAC / Ballon / 101 ET/OU 103</span> */}
          </li>
        </div>
      </ul>
    </>
  );
}
